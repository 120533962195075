export const LayoutTokens = {
  "space": {
    "1X": {
      "value": "4px"
    },
    "2X": {
      "value": "8px"
    },
    "3X": {
      "value": "12px"
    },
    "4X": {
      "value": "16px"
    },
    "5X": {
      "value": "20px"
    },
    "6X": {
      "value": "24px"
    },
    "8X": {
      "value": "32px"
    },
    "12X": {
      "value": "48px"
    },
    "16X": {
      "value": "64px"
    },
    "24X": {
      "value": "96px"
    },
    "32X": {
      "value": "128px"
    }
  },
  "shape": {
    "cornerradius": {
      "tiles": {
        "value": "12px"
      },
      "heroes": {
        "value": "24px"
      },
      "circle": {
        "value": "9999px"
      }
    }
  }
}