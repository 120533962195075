export const AccessibilityTokens = {
  "focusring": {
    "borderstyle": {
      "value": "dashed"
    },
    "borderwidth": {
      "value": "1px"
    },
    "space": {
      "offset": {
        "value": "2px"
      }
    }
  },
  "color": {
    "focusring": {
      "onlight": {
        "value": "#000000"
      },
      "ondark": {
        "value": "#ffffff"
      }
    }
  }
}